
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonPipe } from './common.pipe';
import { HeaderComponent } from '../components/header/header.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { FooterComponent } from '../components/footer/footer.component';
import { SideNavComponent } from '../components/sideNav/sideNav.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { VerifyEmailComponent } from '../components/verify-email/verify-email.component';
import { PaymentConfirmationComponent } from '../components/payment-confirmation/payment-confirmation.component';
import { ResourcesSliderComponent } from '../components/resources-slider/resources-slider.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgSelectModule } from '@ng-select/ng-select';
import { SanitizedHtmlPipe } from '../_helpers/sanitized-html.pipe';
import { PhoneNumberMaskDirective } from './phone-number-mask.directive';
// import { PaymentMethodComponent } from '../pages/payment-method/payment-method.component';


const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  BreadcrumbComponent,
  SideNavComponent,
  LoaderComponent,
  VerifyEmailComponent,
  PaymentConfirmationComponent,
  ResourcesSliderComponent,
]

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule,  NgxFileDropModule, RecaptchaModule, RecaptchaFormsModule, CarouselModule, NgSelectModule ];

const PIPES = [
  CommonPipe,
  SanitizedHtmlPipe
];

const Directive=[
  PhoneNumberMaskDirective
];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES, ...Directive],
  entryComponents: [...COMPONENTS],
  imports: [...BASE_MODULES],
  exports: [...BASE_MODULES, ...COMPONENTS, ...PIPES,...Directive],
  providers: [DatePipe],
  // entryComponents: [...ENTRY_COMPONENTS],
})

export class SharedModule { }
