import { DOCUMENT } from '@angular/common';
import { Component, Inject} from '@angular/core';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'relevant-redio-web';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private commonService: CommonService
  ) {}

}
