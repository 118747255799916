import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { SECRET_KEY } from '../_config/api.config';

declare var forge: any;
var CryptoJS = require("crypto-js");
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  domDirectionLTR = true;

  //For Add new product Steps
  public eventObservable = new Subject<any>();
  public event: any = {};

  //For access profile name from any component
  public profileName = new Subject<string>();

  //For access notification count from any component
  public updatedNotificationCount = new Subject<string>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public encryptData(data: any) {
    try {
      var encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY);
      return encryptedData.toString();
    } catch (e) {
      console.log(e);
    }
  }

  public decryptData(encryptedData: string) {
    if (!encryptedData) {
      return null;
    }
    try {
      var bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
      var decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      return decryptedData;
    } catch (e) {
      console.log(e);
    }
  }
  //For Add new product Steps
  public setEvent(event: string, data: any) {
    this.event = { 'event': event, 'data': data };
    this.eventObservable.next(this.event);
  }

  public downloadFile(data, title?){
    var a = document.createElement("a");
    a.setAttribute("download", title);
    a.setAttribute("href", data);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
