import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { PaymentConfirmationComponent } from './components/payment-confirmation/payment-confirmation.component';
import { PaymentMethodComponent } from './pages/payment-method/payment-method.component';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
    { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
    { path: 'login', canActivate: [AuthGuard], loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
    { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
    { path: 'resources', loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule) },
    { path: 'about-us', loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule) },
    { path: 'notification', canActivate: [AuthGuard], loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule) },
    { path: 'initiate-donation', loadChildren: () => import('./pages/initiate-donation/initiate-donation.module').then(m => m.InitiateDonationModule) },
    { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
    { path: 'terms-of-use', loadChildren: () => import('./pages/terms-of-use/terms-of-use.module').then(m => m.TermsOfUseModule) },
    { path: 'forgot-password', canActivate: [AuthGuard], loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPassowrdModule) },
    { path: 'create-new-password', canActivate: [AuthGuard], loadChildren: () => import('./pages/create-new-password/create-new-password.module').then(m => m.CreateNewPassowrdModule) },
    { path: 'create-account', canActivate: [AuthGuard], loadChildren: () => import('./pages/create-account/create-account.module').then(m => m.CreateAccountModule) },
    { path: 'my-profile', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule) },
    { path: 'contact-us', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
    { path: 'my-address', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-address/my-address.module').then(m => m.MyAddressModule) },
    { path: 'my-files', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-files/my-files.module').then(m => m.MyFilesModule) },
    { path: 'my-forms', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-form/my-form.module').then(m => m.MyFormModule) },
    { path: 'shared-doc', canActivate: [AuthGuard], loadChildren: () => import('./pages/shared-doc/shared-doc.module').then(m => m.SharedDocModule) },
    { path: 'change-password', canActivate: [AuthGuard], loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule) },
    { path: 'my-form-status', canActivate: [AuthGuard], loadChildren: () => import('./pages/my-forms-status/my-form-status.module').then(m => m.MyFormStatusModule) },
    { path: 'verify-email', component: VerifyEmailComponent },
    { path: 'payment-confirmation', component: PaymentConfirmationComponent },
    { path: 'pdf-generation', loadChildren: () => import('./pages/pdf-generation/pdf-generation.module').then(m => m.PdfGenerationModule) },
    { path: 'payment-method', component: PaymentMethodComponent },
    { path: 'cover-page', loadChildren: () => import('./pages/cover-page/cover-page.module').then(m => m.CoverPageModule) },
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
