import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';


const CODEMESSAGE = {
	200: 'The server successfully returned the requested data.',
	201: 'New or modified data is successful.',
	202: 'A request has entered the background queue (asynchronous task).',
	204: 'The data was deleted successfully.',
	400: 'The request was made with an error and the server did not perform any operations to create or modify data.',
	401: 'User does not have permission (token, username, password is incorrect).',
	403: 'The user is authorized, but access is forbidden.',
	404: 'The request is made for a record that does not exist and the server does not operate.',
	406: 'The format of the request is not available.',
	410: 'The requested resource is permanently deleted and will not be retrieved.',
	422: 'A validation error occurred when creating an object.',
	500: 'An error occurred on the server. Please check the server.',
	502: 'Gateway error.',
	503: 'The service is unavailable and the server is temporarily overloaded or maintained.',
	504: 'The gateway timed out.',
};

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	isPopOpen = false;
	constructor(private authService: AuthenticationService,
		private router: Router,
	) { }

	getToken() {
		return localStorage.getItem('accessToken');
	}


	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const token = localStorage.getItem('accessToken');

		const headers: any = {};
		headers['Cache-Control'] = 'no-cache';
		headers['Access-Control-Allow-Origin'] = '*';
		headers['userType'] = 'user';


		if ((token || localStorage.getItem('accessToken'))) {
            headers['authorization'] = token ? token : localStorage.getItem('accessToken');
        } 

		// Use the token in the request
		req = req.clone({
			setHeaders: headers
		});
		// Handle the request
		return next.handle(req).pipe(
			mergeMap((event: any) => {
				if (event instanceof HttpResponseBase) return this.handleData(event);
				return of(event);
			}),
			catchError((err: HttpErrorResponse) => this.handleData(err)),
		);

	}
	private checkStatus(ev: HttpResponseBase) {
		if (ev && ((ev.status >= 200 && ev.status < 300) || ev.status === 401)) {
			return;
		}
		if (ev.status == 0) {
			localStorage.setItem('isNetworkConnected', 'false');
		}
	}

	public handleData(ev: HttpResponseBase): Observable<any> {
		let mytoken = localStorage.getItem('accessToken');
		this.checkStatus(ev);

		switch (ev['status']) {
			case 0:
				return throwError(ev['error']);
			case 200:
				if(ev['body'] && ev['body']['code'] === 401 && !ev['url'].includes('/logout')){
					this.logout();
				}
				break;
			case 400:
				return throwError(ev['error']);
				break;
			case 401:
				this.logout();
				return throwError(ev['error']);
				break;
			case 403:
				this.logout();				
				return throwError(ev['error']);
				break;
			case 404:
				return throwError(ev['error']);
				break;
			case 410:
				return throwError(ev['error']);
				break;
			case 500:
				return throwError(ev['error']);
				break;
			default:
				if (ev instanceof HttpErrorResponse) {
					console.warn(`I don't know the error, most of it is caused by the backend not supporting CORS or invalid configuration.`, ev['error']);
					return throwError(ev['error']);
				}
				break;
		}
		return of(ev);
	}

	
	logout(){
		let mytoken = localStorage.getItem('accessToken');
		this.authService.logout(mytoken);
		localStorage.clear();
		this.router.navigate(['/login']);
	}
}





