import { Component, OnInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api-data.service';
import { URLS } from '../../_config/api.config';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
  innerHtml: any;
  isInvalid;
  private subscription: Subscription = new Subscription();
  constructor(private elRef: ElementRef, private dataService: ApiService) { }

  ngOnInit(): void {
    setTimeout(() => {
      // this.findInnterHTML();
      this.elRef.nativeElement
      .querySelector('#bboxdonation_btnSubmit')
      .addEventListener('click', this.methodName.bind(this));
    }, 1000);
  }
  

  // findInnterHTML() {
  //   let innerData;
  //   innerData = document.getElementById("bboxdonation_payment_lblChoiceBillMeLater");
  //   innerData = document.getElementById("bboxdonation_payment_lblChoiceBillMeLater");
  //   console.log(innerData);
  //   if (innerData) {
  //     this.innerHtml = innerData
  //     console.log(this.innerHtml);
  //   }
    
  // }

  saveDonation(amount) {
    let numericAmt = amount.replace('$', '');
    let params = {
      // paymentMethod: "card",
      // paymentStatus: "successfull",
      donatedAmount: +numericAmt
    }
    console.log(params);
    this.subscription.add(this.dataService.post(URLS.saveDonation, params).subscribe(
      res => {
        if (res['code'] === 200) {
          console.log(res['message']);
        }
      }, 
      error => {
        console.log(error)
      }
    ));
  }

  public methodName() {
    console.log('called');
    setTimeout(() => {
      this.isInvalid = document.getElementById('divClientError');
      console.log('is invalid', this.isInvalid);
    }, 2000);
    
    const firstName = document.getElementById('bboxdonation_billing_txtFirstName') as HTMLInputElement | null;
    if (firstName != null) {
      const value = firstName.value;
      console.log(value); // 👉️ "Initial value"
    }
    const lastName = document.getElementById('bboxdonation_billing_txtLastName') as HTMLInputElement | null;
    if (lastName != null) {
      const value = lastName.value;
      console.log(value); // 👉️ "Initial value"
    }
    const email = document.getElementById('bboxdonation_billing_txtEmail') as HTMLInputElement | null;
    if (email != null) {
      const value = email.value;
      console.log(value); // 👉️ "Initial value"
    }
    const amount = document.getElementsByClassName('BBFormCurrency')[0] as HTMLInputElement | null;
    if (amount != null) {
      const value = amount.value;
      console.log(value); // 👉️ "Initial value"
      setTimeout(() => {
        if (amount.value && !this.isInvalid) {
          this.saveDonation(value);
        }
      }, 3000);
    }
    if (!amount.value) {
      const selectedAmount = document.getElementsByClassName('BBFormRadioLabelGivingLevelSelectedDC')[0] as HTMLInputElement | null;
      if (selectedAmount != null) {
        const selectedAmountValue = selectedAmount.getElementsByClassName('BBFormRadioAmount')[0].innerHTML
        console.log(selectedAmountValue); // 👉️ "Initial value"
        setTimeout(() => {
          if (selectedAmountValue && !this.isInvalid) {
            this.saveDonation(selectedAmountValue);
          }
        }, 3000);
      }
    }
    
    
  }



}
