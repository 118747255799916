<app-loader *ngIf="isLoading"></app-loader>
<div class="loader text-center" *ngIf="!isPaymentConfirmed">
    <div>
        <div class="spinner-border text-warning" role="status">
        </div>
        <h3>Please wait, your payment is in progress...!</h3>
    </div>
</div>
<div class="bg-image" *ngIf="isPaymentConfirmed">
    <app-header></app-header>
    <div class="container section-wrap" *ngIf="!isPaymentFailed">
        <h3>Thank you for your Contribution!</h3>
        <br>
        <div class="thankyouSection">
            <div class="text-center" *ngIf="!isFinalPdfGenerated">
                <div class="spinner-border text-warning" role="status"></div>
                <p class="responsive-paragraph">Thank you for submitting the {{formTitle}} form. You will be able to
                    download a PDF copy shortly.</p>
            </div>
            <div *ngIf="isFinalPdfGenerated">
                <div class="text-center pdfImage"><img src="./assets/images/pdf.svg"></div>
                <p class="msg-text"
                    *ngIf="formID !=='6490049fe7bc6b6649320fe5' && formID !=='64e2f30f463b8795bcf3a85f' && formID !=='64ccdf603f11d4fb4c0174a8' && formID !=='657b58eb62f1c8691a582f98' ">
                    Your PDF is generated, You can download it !</p>
                <p class="msg-text"
                    *ngIf="formID=='6490049fe7bc6b6649320fe5' || formID=='64e2f30f463b8795bcf3a85f' || formID=='657b58eb62f1c8691a582f98'">
                    Your PDF has been successfully generated. Please follow these steps:<br>
                    1. Download and print the document.<br>
                    2. Sign the document according to the instructions provided on its cover page.<br>
                    3. Upload the signed copy to the "My Completed Documents" section on your profile page for secure storage and easy sharing.<br>
                    4. Keep your printed and signed copy in a secure location, such as a safe or file cabinet.<br>
                    <br>
                    Thank you for completing this process. Please spread the word about Catholic Legacy and help us
                    reach more people!
                </p>
                <p class="msg-text" *ngIf="formID=='64ccdf603f11d4fb4c0174a8'">
                    Your Funeral Plan PDF has been successfully generated. To share it with your parish, funeral home, or family, you have two options:<br>
                    1. Download and print the document.<br>
                    2. Use the "Securely Share this Document with Others" button found below or in the "My Completed Documents" section of your profile page.<br><br>
                    Thank you for completing this process. Please spread the word about Catholic Legacy and help us reach more people!
                    <br>
                </p>
                <div class="sbmt-btn-container">
                    <button class="btn btn-primary border-button-repeatable mr-16 btn-donate"
                        (click)="OnMyForm()">Return to My Forms</button>
                    <button class="btn btn-primary border-button-repeatable btn-donate mr-16" (click)="downloadPdf()"
                        [disabled]="pdfDowloading">Download</button><br>
                        </div>
                   <div class="left-btn-container">
                    <button class="btn btn-primary border-button-repeatable btn-donate mr-16"
                        (click)="shareModalOpen()">Securely Share this Document with others</button>
                        <br><br>
                            <!-- <button class="btn-share btn btn-primary btn btn-primary border-button-repeatable btn-donate  btn-block border-button">
                               <span class="btn-text">Tell Others About Catholic Legacy</span>
                               <span class="btn-icon">
                                 <svg
                                   t="1580465783605"
                                   class="icon"
                                   viewBox="0 0 1024 1024"
                                   version="1.1"
                                   xmlns="http://www.w3.org/2000/svg"
                                   p-id="9773"
                                   width="18"
                                   height="18"
                                 >
                                   <path
                                     d="M767.99994 585.142857q75.995429 0 129.462857 53.394286t53.394286 129.462857-53.394286 129.462857-129.462857 53.394286-129.462857-53.394286-53.394286-129.462857q0-6.875429 1.170286-19.456l-205.677714-102.838857q-52.589714 49.152-124.562286 49.152-75.995429 0-129.462857-53.394286t-53.394286-129.462857 53.394286-129.462857 129.462857-53.394286q71.972571 0 124.562286 49.152l205.677714-102.838857q-1.170286-12.580571-1.170286-19.456 0-75.995429 53.394286-129.462857t129.462857-53.394286 129.462857 53.394286 53.394286 129.462857-53.394286 129.462857-129.462857 53.394286q-71.972571 0-124.562286-49.152l-205.677714 102.838857q1.170286 12.580571 1.170286 19.456t-1.170286 19.456l205.677714 102.838857q52.589714-49.152 124.562286-49.152z"
                                     p-id="9774"
                                     fill="#ffffff"
                                   ></path>
                                 </svg>
                               </span>
                               <ul class="social-icons">
                                 <li>
                                   <a (click)="share('whatsapp')"><img class="social-icon" src="./assets/images/icons8-whatsapp.svg"
                                     alt="Relevant-Logo"></a>
                                 </li>
                                 <li>
                                   <a (click)="share('facebook')"><img class="social-icon" src="./assets/images/icons8-facebook.svg"></a>
                                 </li>
                                 <li>
                                   <a (click)="share('linkedin')"><img class="social-icon" src="./assets/images/icons8-linked-in.svg"></a>
                                 </li>
                                 <li>
                                   <a (click)="share('twitter')"><img class="social-icon" src="./assets/images/icons8-twitterx.svg"></a>
                                 </li>
                               </ul>
                             </button> -->
                           </div>
            </div>
        </div>
    </div>
    <div class="container section-wrap" *ngIf="isPaymentFailed">
        <h3>Your payment is unsuccessful, Please try again !</h3>
    </div>
</div>


<button style="display: none;" data-bs-toggle="modal" data-bs-target="#shareModal"
    class="btn btn-primary btn-block border-button" #openShareModal></button>

<div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="d-flex justify-content-center align-items-center formContainer">
                        <div class="form-group">
                            <label>Email Addresses *</label>
                            <div class="custom-input">
                                <input name="email" #emailInputElement [(ngModel)]="inputValue"
                                    [ngClass]="{ 'is-invalid': invalidEmail }" (input)="onInputChange($event)"
                                    (focus)="showSuggestions = true" />
                                <div *ngIf="invalidEmail && !this.showSuggestions" class="invalid-feedback">
                                    <div *ngIf="invalidEmail">Please Enter Valid Email</div>
                                </div>
                                <ng-container *ngIf="showSuggestions && suggestions.length>0">
                                    <ul class="suggestion-list">
                                        <li *ngFor="let suggestion of suggestions"
                                            (click)="selectSuggestion(suggestion)">
                                            {{ suggestion }}
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </div>
                        <button type="submit" (click)="onShare()" [disabled]="tags.length==0"
                            class="btn btn-primary share">SHARE</button>
                    </div>
                    <ng-container class="tags-container" *ngIf="tags.length>0">
                        <div class="tags">
                            <span class="tag" *ngFor="let tag of tags">
                                {{ tag }} <span class="remove-tag" (click)="removeTag(tag)">×</span>
                            </span>
                        </div>
                    </ng-container>
                </form>
                <div class="copy-div">
                    <input id="copyText" type="text" [value]="fileUrl">
                    <button (click)="copy()">Copy</button>
                </div>
                <hr>
                <div class="share-wrap" *ngIf="sharedEmail.length">
                    <h2>Shared with</h2>
                    <ul>
                        <li *ngFor="let data of sharedEmail">{{data}}</li>
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>
</div>